import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, Input } from '@angular/core';
import { Stage } from 'src/app/models/stage.model';

@Component({
  selector: 'app-stage-card',
  templateUrl: './stage-card.component.html',
  styleUrl: './stage-card.component.scss'
})
export class StageCardComponent {
  @Input() stage!: Stage;
  @Input({transform: coerceBooleanProperty}) title: boolean = false;
}
