<h2 mat-dialog-title> 
	<!-- <mat-icon>tune</mat-icon> -->
	<span>Nouvelle configuration dans {{ data.projectNameToAttach }}</span>
</h2>
<mat-dialog-content class="mat-typography">
	<!-- Name -->
  <mat-form-field subscriptSizing="dynamic" floatLabel="always">
		<mat-label>Nouvelle configuration</mat-label>
		<input matInput #input>
	</mat-form-field>

	<!-- Stage -->
	<mat-form-field subscriptSizing="dynamic" floatLabel="always">
		<mat-label>Environnement</mat-label>
		<mat-select [(ngModel)]="selectedStageId" name="stage">
			@for (stage of stages; track stage) {
				<mat-option [value]="stage.id">
					<app-stage-card [stage]="stage"></app-stage-card>
				</mat-option>
			}
		</mat-select>
	</mat-form-field>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Annuler</button>
  <button mat-raised-button (click)="confirm(input.value)" color="primary"
  [disabled]="!input.value || input.value === data.projectNameToAttach">
  <mat-icon>edit</mat-icon>
  Confirmer</button>
</mat-dialog-actions>
