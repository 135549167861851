import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { firstValueFrom } from 'rxjs';
import { getNodeIcon } from 'src/app/models/global.model';
import { ProjectCrudService } from 'src/app/services/crud/project-crud.service';

export interface DialogCreateProjectData {
  customerNameToAttach: string;
  customerIdToAttach: number;
}

@Component({
  selector: 'app-dialog-create-project-dialog',
  templateUrl: './dialog-create-project-dialog.component.html',
  styleUrl: './dialog-create-project-dialog.component.scss'
})
export class DialogCreateProjectDialogComponent {
  icon: string = '';

  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogCreateProjectData,
  private matDialog: MatDialogRef<DialogCreateProjectDialogComponent>,
  private projectApi: ProjectCrudService,
  private snackbar: MatSnackBar) 
  {
    this.icon = getNodeIcon('project');
  }

  async confirm(name: string) {
    try {
      const newProject = await firstValueFrom(this.projectApi.createProject(name, this.data.customerIdToAttach));
      this.snackbar.open(`Projet ${newProject.name} créé.`, 'Ok', { duration: 3000 });
      this.matDialog.close(newProject);
    } catch (error) {
      this.snackbar.open((error as Error).message);
    }
  }
}
