import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpEvent
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { TreeService } from '../services/tree.service';
import { Router } from '@angular/router';
import { NodeType } from '../models/global.model';

@Injectable()
export class RefreshInterceptor implements HttpInterceptor {
  constructor(
		private treeService: TreeService,
		private router: Router) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap(() => {
				const urlSegments = this.router.url.split('/');
    		const index = urlSegments.findIndex(segment => !isNaN(Number(segment)) && segment !== '');

				// Refresh when modified datas
				if ((request.url.includes('customer') || request.url.includes('project') || request.url.includes('config'))
				&& (request.method === 'PUT' || request.method === 'POST' || request.method === 'DELETE')) {
					this.treeService.refreshTree();
				}
      })
    );
  }

}

