export type NodeType = 'customer' | 'project' | 'config';
export const NODE_TYPES = ['customer', 'project', 'config'];

export enum NODES {
	CUSTOMER = 'customer',
	PROJECT = 'project',
	CONFIG = 'config'
}

export const getNodeIcon = (type: NodeType) => {
	switch (type) {
		case NODES.CUSTOMER:
			return 'people';
		case NODES.PROJECT:
			return 'folder';
		case NODES.CONFIG:
			return 'tune';
		default:
			return '';
	}
}

const DIALOG_WIDTH = 500;
// const DIALOG_HEIGHT = 500;


export const DIALOG_SIZE = {
	width: `${DIALOG_WIDTH}px`
}