import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NODE_TYPES } from 'src/app/models/global.model';
import { AuthService } from 'src/app/services/auth.service';
import { DrawerService } from 'src/app/services/drawer.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  openedDrawer: boolean = true;

  constructor(private authService: AuthService, public router: Router,
    public userService: UserService, private drawerService: DrawerService) {}

  get user() {
    return this.userService.user;
  }

  ngOnInit() {
    if (true) {
      this.drawerService.getDrawerState().subscribe((isOpen: boolean) => {
        this.openedDrawer = isOpen;
      });
    }
  }

  logout() {
    this.authService.logout();
  }

  toggleDrawer() {
    this.drawerService.toggleDrawer(!this.openedDrawer);
  }

  isInProjects() {
    // ! Add here some routes to keep the toggle button to show tree
    const treeRoutes: string[] = ['home', ...NODE_TYPES];
    return treeRoutes.some(route => this.router.url.includes(route));
  }
}
