import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Project } from 'src/app/models/project.model';
import { environment } from 'src/environment';

@Injectable({
  providedIn: 'root'
})
export class ProjectCrudService {
	private baseUrl = `${environment.apiUrl}/project`;

  constructor(private http: HttpClient) { }

	// Get
  getProjects(): Observable<Project[]> {
    return this.http.get<Project[]>(this.baseUrl);
  }
  getProjectById(projectId: number): Observable<Project> {
    return this.http.get<Project>(`${this.baseUrl}/${projectId}`);
  }
  // Post
  createProject(name: string, customerId: number, configIds?: number[]): Observable<Project> {
    const data = {
      name,
      customerId,
      configIds: configIds || [],
    }
    return this.http.post<Project>(this.baseUrl, data);
  }
  // Put
  editProject(projectId: number, project: Partial<Project>): Observable<Project> {
    return this.http.put<Project>(`${this.baseUrl}/${projectId}`, { name: project.name });
  }
  // Delete
  deleteProject(projectId: number): Observable<any> {
    return this.http.delete(`${this.baseUrl}/${projectId}`, { responseType: 'text'});
  }
}
