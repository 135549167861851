import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, tap } from 'rxjs';
import { Config } from 'src/app/models/config.model';
import { environment } from 'src/environment';
import { TreeService } from '../tree.service';

@Injectable({
  providedIn: 'root'
})
export class ConfigCrudService {
  private baseUrl = `${environment.apiUrl}/config`;

  constructor(private http: HttpClient) { }

	// Get
  getConfigs(): Observable<Config[]> {
    return this.http.get<Config[]>(this.baseUrl);
  }
  getConfigById(configId: number): Observable<Config> {
    return this.http.get<Config>(`${this.baseUrl}/${configId}`);
  }
  // Post
  createConfig(name: string, stageId?: number): Observable<Config> {
    const projectIds: number[] = [];
    return this.http.post<Config>(this.baseUrl, { name, projectIds, stageId });
  }
  attachProject(configId: number, projectId: number) {
    return this.http.post<Config>(`${this.baseUrl}/${configId}/attachProject`, { projectId });
  }
  detachProject(configId: number, projectId: number) {
    return this.http.post<Config>(`${this.baseUrl}/${configId}/detachProject`, { projectId });
  }
  attachStage(configId: number, stageId: number) {
    return this.http.post<Config>(`${this.baseUrl}/${configId}/attachStage`, { stageId });
  }
  detachStage(configId: number) {
    return this.http.post<Config>(`${this.baseUrl}/${configId}/detachStage`, {});
  }
  // Put
  editConfig(config: Config): Observable<Config> {
    return this.http.put<Config>(`${this.baseUrl}/${config.id}`, config);
  }
  saveConfig(configId: number, schemaId: string, modelIds: string[]) {
    return this.http.put(`${this.baseUrl}/${configId}/save`, { schemaIds: [schemaId], modelIds }, { responseType: 'text' });
  }
  deployConfig(configId: number) {
    return this.http.put(`${this.baseUrl}/${configId}/deploy`, {}, { responseType: 'text' });
  }
  undeployConfig(configId: number) {
    return this.http.put(`${this.baseUrl}/${configId}/undeploy`, {}, { responseType: 'text' });
  }
  // Delete
  deleteConfig(configId: number): Observable<any> {
    return this.http.delete(`${this.baseUrl}/${configId}`, { responseType: 'text' });
  }
}
