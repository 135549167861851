import { Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSelectionList } from '@angular/material/list';
import { Observable, Subscription, lastValueFrom, of } from 'rxjs';
import { User } from 'src/app/models/user.model';
import { CustomerCrudService } from 'src/app/services/crud/customer-crud.service';
import { UserCrudService } from 'src/app/services/crud/user-crud.service';

export interface DialogAddUserData {
  level: 'group' | 'project' | 'config';
  dataId: number
}

@Component({
  selector: 'app-dialog-add-user-dialog',
  templateUrl: './dialog-add-user-dialog.component.html',
  styleUrl: './dialog-add-user-dialog.component.scss'
})
export class DialogAddUserDialogComponent implements OnInit {
  @ViewChild('userList') userList!: MatSelectionList

  filteredUsers: User[] = [];
  users: User[] = [];

  private users$: Observable<User[]> = of([]);

  // TODO: Define services to import users from level
  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogAddUserData,
    private customerApi: CustomerCrudService, private userApi: UserCrudService,
    private matDialog: MatDialogRef<DialogAddUserDialogComponent>
  ) {
    // Get level to find how to get users
    switch (data.level) {
      case 'group':
        this.users$ = this.userApi.getUsers();
        break;
      case 'project':
        // this.users$ = this.userApi.getUsersByGroup(this.data.dataId);
        break;
      case 'config':
        // this.users$ = this.userApi.getUsersByProject(this.data.dataId);
        break;
      default:
        this.users$ = this.userApi.getUsers();
        break;
    }
  }

  async ngOnInit(): Promise<void> {
    // Get ponctually some users
    // TODO: To uncomment
    // this.users = await lastValueFrom(this.users$);
    this.filteredUsers = this.users;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value.toLowerCase();
    this.filteredUsers = this.users.filter(
      (user) =>
        user.name.toLowerCase().includes(filterValue) ||
        user.mail.toLowerCase().includes(filterValue)
    );
  }

  confirm() {
    this.matDialog.close(this.userList._value);
  }
}
