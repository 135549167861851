<h2 mat-dialog-title>
	<!-- <mat-icon>{{ icon }}</mat-icon> -->
	Duplication de {{ data.projectName }}
</h2>
<mat-dialog-content class="mat-typography">
	<!-- Customer list to assign -->
	<mat-form-field subscriptSizing="dynamic" floatLabel="always">
		<mat-label>Client</mat-label>
		<mat-select [formControl]="customersCtrl" multiple>
			<mat-select-trigger>
				{{customersCtrl.value?.[0]?.name || ''}}
				@if ((customersCtrl.value?.length || 0) > 1) {
					<span class="example-additional-selection">
						(+{{(customersCtrl.value?.length || 0) - 1}} {{customersCtrl.value?.length === 2 ? 'autre' : 'autres'}})
					</span>
				}
			</mat-select-trigger>
			@for (customer of userCustomers; track customer) {
		<mat-option [value]="customer">{{customer.name}}</mat-option>
	}
		</mat-select>
	</mat-form-field>

	<!-- New name (or not) -->
  <mat-form-field subscriptSizing="dynamic" floatLabel="always">
		<mat-label>Nom du projet</mat-label>
		<input matInput #input [value]="data.projectName">
	</mat-form-field>

	<!-- Import nested data ? TODO -->
	<mat-checkbox disabled [(ngModel)]="duplicateRelatedConfigs">Dupliquer les configurations liées</mat-checkbox>

<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Annuler</button>
  <button mat-raised-button (click)="confirm(input.value)"
  [disabled]="customersCtrl.value?.length === 0">
  <mat-icon>add</mat-icon>
  Appliquer</button>
</mat-dialog-actions>
