<h2 mat-dialog-title> 
	<span>Nouveau projet dans {{ data.customerNameToAttach }}</span>
</h2>
<mat-dialog-content class="mat-typography">
	<!-- Name -->
  <mat-form-field subscriptSizing="dynamic" floatLabel="always">
		<mat-label>Nouveau projet</mat-label>
		<input matInput #input>
	</mat-form-field>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Annuler</button>
  <button mat-raised-button (click)="confirm(input.value)" color="primary"
  [disabled]="!input.value || input.value === data.customerNameToAttach">
  <mat-icon>edit</mat-icon>
  Confirmer</button>
</mat-dialog-actions>

