import { Injectable } from '@angular/core';
import { TreeNode, UrlTreeNode } from '../models/tree-node.model';
import { NestedTreeControl } from '@angular/cdk/tree';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { Customer } from '../models/customer.model';
import { StageCrudService } from './crud/stage-crud.service';
import { CustomerCrudService } from './crud/customer-crud.service';
import { firstValueFrom, map, Observable, Subject, timer } from 'rxjs';
import { Stage } from '../models/stage.model';
import { NODES, NodeType, getNodeIcon } from '../models/global.model';

@Injectable({
  providedIn: 'root',
})

/**
 * Useful for retrieving data from route (tree node id)
 */
export class TreeService {
  private refreshTreeSubject = new Subject<void>();
  refreshIntervalMs = 120 * 1000; // 2 minutes

  private treeStateSubject = new Subject<void>();

  public treeControl = new NestedTreeControl<TreeNode>(node => node.children);
  public dataSource = new MatTreeNestedDataSource<TreeNode>();
  public expandedNodes: TreeNode[] = []

  public get nestedTree() {
    return this.dataSource.data;
  }

  public refreshTree() {
    this.refreshTreeSubject.next();
  }

  public setTreeToReady() {
    this.treeStateSubject.next();
  }

  getTreeState() {
    return this.treeStateSubject.asObservable();
  }

  getManualRefreshTreeState(): Observable<void> {
    return this.refreshTreeSubject.asObservable();
  }

  getAutoRefreshTreeState(): Observable<number> {
    return timer(0, this.refreshIntervalMs).pipe(
      map(() => Date.now())
    );
  }

  /**
   * Get all nodes from tree with array disposition with hierarchy.
   */
  public get flatNestedTree() {
    let result: TreeNode[] = [];
    for (const customerNode of this.dataSource.data) {
      result.push(customerNode);
      for (const projectNode of customerNode.children) {
        result.push(projectNode);
        for (const configNode of projectNode.children) {
          result.push(configNode);
        }
      }
    }
    return result;
  }

  public get treeProjects() {
    return this.flatNestedTree.find(node => node.type === 'project');
  }

  public get treeConfigs() {
    return this.flatNestedTree.find(node => node.type === 'config');
  }

  constructor(
    private customerApi: CustomerCrudService,
    private stageApi: StageCrudService) 
  {}

  getNodeById(nodeType: NodeType, nodeId: number): TreeNode | undefined {
    return this.flatNestedTree.find(node => node.type === nodeType && node.id === nodeId);
  }

  /**
   * Find the parent from the reference node in input.
   * @param baseNode - Node we refer to find the parent node.
   * @returns The parent node.
   */
  findParentNode(refNode: TreeNode) {
    return this.flatNestedTree.find(node => node.type === refNode.parentType && node.id === refNode.parentId);
  }

  /**
   * Convert each node's group to sorted tree node.
   * @param groups
   * @returns Complete tree to show - Adapted for nested-data-tree.
   */
  async generateTree() {
    let treeNodes: TreeNode[] = [];
    const userCustomers: Customer[] = await firstValueFrom(this.customerApi.getCustomers());
    if (userCustomers.length > 0) {
      let stages: Stage[] = await firstValueFrom(this.stageApi.getStages());
      // Generation with creation loops
      for (const customer of userCustomers) {
        const customerNode: TreeNode = {
          id: customer.id,
          level: 1,
          name: customer.name,
          type: NODES.CUSTOMER,
          children: [],
          icon: getNodeIcon(NODES.CUSTOMER),
        };

        if (customer.projects && customer.projects.length > 0) {
          for (const project of customer.projects) {
            const projectNode: TreeNode = {
              id: project.id,
              level: 2,
              name: project.name,
              type: NODES.PROJECT,
              icon: getNodeIcon(NODES.PROJECT),
              children: [],
              parentType: 'customer',
              parentId: customer.id,
            };
            customerNode.children.push(projectNode);

            if (project.configs && project.configs.length > 0) {
              for (const config of project.configs) {
                const stage = stages.find(stage => stage.id === config.stageId);
                const configNode: TreeNode = {
                  id: config.id,
                  level: 3,
                  name: config.name,
                  type: NODES.CONFIG,
                  children: [],
                  stage,
                  icon: getNodeIcon(NODES.CONFIG),
                  parentType: 'project',
                  parentId: project.id,
                  locked: config.locked
                };
                projectNode.children!.push(configNode);
              }
            }
            // Sort configs by project
            projectNode.children!.sort((a, b) => a.name.localeCompare(b.name));
          }
        }
        // Sort projects by customer
        customerNode.children!.sort((a, b) => a.name.localeCompare(b.name));
        treeNodes.push(customerNode);
      }
      // Sort customers
      this.dataSource.data = treeNodes.sort((a, b) => a.name.localeCompare(b.name));
    }
    this.treeControl.dataNodes = this.dataSource.data;
    this.expandNodesFromHierarchy();

    this.setTreeToReady();

    return treeNodes || [];
  }

  // When we refresh tree, we have to expand again some nodes to retrieve the same visual compared to earlier
  expandNodesFromHierarchy(): void {
    this.dataSource.data.forEach((customerNode, customerIdx) => {
      if (this.findExpandedNode(customerNode.id, customerNode.type)) {
        this.treeControl.expand(this.dataSource.data[customerIdx]);
      }
      customerNode.children.forEach((projectNode, projectIdx) => {
        if (this.findExpandedNode(projectNode.id, projectNode.type)) {
          this.treeControl.expand(this.dataSource.data[customerIdx].children[projectIdx]);
        }
        projectNode.children.forEach((configNode, configIdx) => {
          if (this.findExpandedNode(configNode.id, configNode.type)) {
            this.treeControl.expand(this.dataSource.data[customerIdx].children[projectIdx].children[configIdx]);
          }
        });
      });
    });
  }

  findExpandedNode(id: number, type: NodeType) {
    return this.expandedNodes.find(expNode => expNode.id === id && expNode.type === type);
  }

  findIndexExpandedNode(id: number, type: NodeType) {
    return this.expandedNodes.findIndex(expNode => expNode.id === id && expNode.type === type);
  }

  /**
   * Expand the tree to retrieve visually the selected node.
   * @param node 
   */
  expandNodeHierarchy(node: TreeNode) {
    if (node.type === 'project') {
      const customerNode = this.findParentNode(node)!;
      this.treeControl.expand(customerNode);
    }
    if (node.type === 'config') {
      const projectNode = this.findParentNode(node)!;
      const customerNode = this.findParentNode(projectNode)!;
      this.treeControl.expand(customerNode);
      this.treeControl.expand(projectNode);
    }
  }

  /**
   * Make an url tree (I could make a recursive function...).
   * @param node 
   * @returns Array with url tree options (name and navigation info).
   */
  getUrlTreeFromNodeId(node: TreeNode): UrlTreeNode[] {
    let result: UrlTreeNode[] = [];
    if (node.type === 'config') {
      const projectNode = this.findParentNode(node)!;
      const customerNode = this.findParentNode(projectNode)!;
      result.push(
        { name: customerNode.name, type: customerNode.type, redirect: [`/${customerNode.type}`, customerNode.id] },
        { name: projectNode.name, type: projectNode.type, redirect: [`/${projectNode.type}`, projectNode.id] },
        { name: node.name, type: node.type, redirect: [`/${node.type}`, node.id], stage: node.stage, locked: node.locked });
    }
    if (node.type === 'project') {
      const customerNode = this.findParentNode(node)!;
      result.push(
        { name: customerNode.name, type: customerNode.type, redirect: [`/${customerNode.type}`, customerNode.id] },
        { name: node.name, type: node.type, redirect: [`/${node.type}`, node.id] });
    }
    if (node.type === 'customer') {
      result.push({ name: node.name, type: node.type, redirect: [`/${node.type}`, node.id] });
    }
    return result;
  }
}
