<div class="config-item" matRipple (click)="navigate()" 
[cdkContextMenuDisabled]="disableContextMenu"
[cdkContextMenuTriggerFor]="menu"
>
	@if (showDeploymentIndicator && !deployed) {
		<mat-icon
			class="not-deployed"
			color="warn" 
			matTooltip="This configuration has not been deployed since it was last modified."
			matTooltipPosition="above"
			i18n-matTooltip>
			error_outline
		</mat-icon>
	}

	<span class="config-name">{{ config.name }}</span>

	<div class="layout">
		@if (config.locked) {
			<mat-icon 
			class="locked"
			matTooltip="Someone is currently editing this configuration."
			i18n-matTooltip>
				lock
			</mat-icon>
		}

	@if (stage && stage.color && showStageColor) {
		<app-stage-card [stage]="stage"></app-stage-card>
	}	
	</div>
</div>

<div class="dates">
	@if (showModificationDate && config.updatedAt) {
		<div
			class="date"
			matTooltip="Date of last modification"
			i18n-matTooltip>

			<mat-icon>save</mat-icon>
			<span>{{ config.updatedAt | date: 'dd/MM/yyyy HH:mm' }}</span>
		</div>
	}
	@if (showDeploymentDate) {
		<div
			class="date"
			matTooltip="Date of last deployment"
			i18n-matTooltip>

			@if (config.deployDate) {
				<mat-icon>send</mat-icon>
				<span>{{ config.deployDate | date: 'dd/MM/yyyy HH:mm' }}</span>
			} @else {
				<mat-icon>block</mat-icon>
				<span i18n>Inactive</span>
			}
		</div>
	}
</div>

<ng-template #stages>
  <div cdkMenu class="mat-elevation-z2">
		@if (availableStages && availableStages.length > 0) {
			@for (stage of availableStages; track $index) {
				<button mat-button cdkMenuItem (click)="copyTo(stage)">
					<app-stage-card [stage]="stage"></app-stage-card>
				</button>
			}
		}
  </div>
</ng-template>

<ng-template #menu>
  <div cdkMenu class="mat-elevation-z2">
		<button mat-button cdkMenuItem (click)="navigate()">
			<mat-icon>tune</mat-icon>
			<span i18n>Open</span>
		</button>
		<button mat-button cdkMenuItem [cdkMenuTriggerFor]="stages">
			<mat-icon>control_point_duplicate</mat-icon>
			<span i18n>Copy to</span>
		</button>
    <button mat-button cdkMenuItem (click)="remove()">
			<mat-icon color="warn">delete</mat-icon>
			<span i18n>Delete</span>
		</button>
  </div>
</ng-template>
