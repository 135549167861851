import { NgModule } from '@angular/core';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatMenuModule } from '@angular/material/menu';
import { MatInputModule } from '@angular/material/input';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatOptionModule, MatRippleModule } from '@angular/material/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSortModule } from '@angular/material/sort';
import { MatListModule } from '@angular/material/list';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatFormFieldModule } from '@angular/material/form-field';
import { CdkMenuModule } from '@angular/cdk/menu';
import { DragDropModule } from '@angular/cdk/drag-drop';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import { MatSelectModule } from '@angular/material/select';
import { FirstLetterUppercasePipe } from './pipes/first-letter-uppercase.pipe';
import { CommonModule } from '@angular/common';
import { ConfigCardComponent } from './config-card/config-card.component';
import { DialogCreateConfigDialogComponent } from './dialogs/create/dialog-create-config-dialog/dialog-create-config-dialog.component';
import { DialogCreateProjectDialogComponent } from './dialogs/create/dialog-create-project-dialog/dialog-create-project-dialog.component';
import { DialogCreateCustomerDialogComponent } from './dialogs/create/dialog-create-customer-dialog/dialog-create-customer-dialog.component';
import { DialogDuplicateConfigDialogComponent } from './dialogs/duplicate/dialog-duplicate-config-dialog/dialog-duplicate-config-dialog.component';
import { DialogDuplicateProjectDialogComponent } from './dialogs/duplicate/dialog-duplicate-project-dialog/dialog-duplicate-project-dialog.component';
import { DialogDeleteConfigDialogComponent } from './dialogs/delete/dialog-delete-config-dialog/dialog-delete-config-dialog.component';
import { DialogDeleteProjectDialogComponent } from './dialogs/delete/dialog-delete-project-dialog/dialog-delete-project-dialog.component';
import { DialogAddUserDialogComponent } from './dialog-add-user-dialog/dialog-add-user-dialog.component';
import { DialogConfirmTransferConfigDialogComponent } from './dialogs/duplicate/dialog-confirm-transfer-config-dialog/dialog-confirm-transfer-config-dialog.component';
import { DialogDeleteCustomerDialogComponent } from './dialogs/delete/dialog-delete-customer-dialog/dialog-delete-customer-dialog.component';
import { StageCardComponent } from './stage-card/stage-card.component';


const materialModules = [
  MatButtonModule,
	MatFormFieldModule,
	MatToolbarModule,
	MatIconModule,
	MatDatepickerModule,
	MatProgressBarModule,
	MatMenuModule,
  MatInputModule,
	MatTableModule,
	MatTabsModule, 
	MatCheckboxModule,
	MatChipsModule,
	MatDividerModule,
	MatRippleModule,
	MatSnackBarModule,
	MatTooltipModule,
	MatDialogModule,
	MatListModule,
	MatPaginatorModule,
	MatSortModule,
	MatSelectModule,
	MatOptionModule,
	MatProgressSpinnerModule,
	MatBadgeModule,
	MatAutocompleteModule,
	CdkMenuModule,
	DragDropModule,

	FormsModule
];

const databaseModules = [
	HttpClientModule
];

const dialogComponents = [
	DialogAddUserDialogComponent,

	DialogCreateConfigDialogComponent,
	DialogCreateProjectDialogComponent,
	DialogCreateCustomerDialogComponent,

	DialogDuplicateConfigDialogComponent,
	DialogConfirmTransferConfigDialogComponent,
	DialogDuplicateProjectDialogComponent,

	DialogDeleteConfigDialogComponent,
	DialogDeleteProjectDialogComponent,
	DialogDeleteCustomerDialogComponent
]

const customComponents = [
	ConfigCardComponent,
	StageCardComponent
]

@NgModule({
	declarations: [
		...dialogComponents,

		...customComponents,

		FirstLetterUppercasePipe,
	],
  imports: [CommonModule, ReactiveFormsModule, ...materialModules, ...databaseModules],
  exports: [CommonModule, ReactiveFormsModule, ...materialModules, ...databaseModules, ...dialogComponents, ...customComponents],
})
export class SharedModule { }