import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { UserSettingsComponent } from './user-settings/user-settings.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AdminComponent } from './admin/admin.component';

const routes: Routes = [
	// {
	// 	path: '',
	// 	redirectTo: '',
	// 	pathMatch: 'full'
	// },
	// { path: '', redirectTo: 'home', pathMatch: 'full' },
  { 
		path: '',
		loadChildren: () => import('./home/home.module').then(m => m.HomeModule) 
	},
	{  path: 'dashboard', component: DashboardComponent },
	{  path: 'profile/settings', component: UserSettingsComponent },
	{  path: 'notifications', component: NotificationsComponent },
	{  path: 'admin',
		loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule) 
	},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {onSameUrlNavigation: 'reload'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
