import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { firstValueFrom } from 'rxjs';
import { getNodeIcon } from 'src/app/models/global.model';
import { CustomerCrudService } from 'src/app/services/crud/customer-crud.service';
import { ProjectCrudService } from 'src/app/services/crud/project-crud.service';

@Component({
  selector: 'app-dialog-create-customer-dialog',
  templateUrl: './dialog-create-customer-dialog.component.html',
  styleUrl: './dialog-create-customer-dialog.component.scss'
})
export class DialogCreateCustomerDialogComponent {
  icon: string = '';

  constructor(private matDialog: MatDialogRef<DialogCreateCustomerDialogComponent>,
  private customerApi: CustomerCrudService,
  private snackbar: MatSnackBar) 
  {
    this.icon = getNodeIcon('project');
  }

  async confirm(name: string, domain: string) {
    try {
      const newCustomer = await firstValueFrom(this.customerApi.createCustomer(name, domain));
      this.snackbar.open(`Client ${newCustomer.name} créé.`, 'Ok', { duration: 3000 });
      this.matDialog.close(newCustomer);
    } catch (error) {
      this.snackbar.open((error as Error).message);
    }
  }
}
