import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { firstValueFrom } from 'rxjs';
import { getNodeIcon } from 'src/app/models/global.model';
import { Stage } from 'src/app/models/stage.model';
import { ConfigCrudService } from 'src/app/services/crud/config-crud.service';
import { StageCrudService } from 'src/app/services/crud/stage-crud.service';

export interface DialogCreateConfigData {
  projectNameToAttach: string;
  projectIdToAttach: number
}

@Component({
  selector: 'app-dialog-create-config-dialog',
  templateUrl: './dialog-create-config-dialog.component.html',
  styleUrl: './dialog-create-config-dialog.component.scss'
})
export class DialogCreateConfigDialogComponent implements OnInit {
  stages: Stage[] = [];
  selectedStageId?: number;

  icon: string = '';

  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogCreateConfigData,
  private matDialog: MatDialogRef<DialogCreateConfigDialogComponent>,
  private stageApi: StageCrudService,
  private configApi: ConfigCrudService,
  private snackbar: MatSnackBar) {
    this.icon = getNodeIcon('config');
  }

  async ngOnInit() {
    try {
      this.stages = await firstValueFrom(this.stageApi.getStages());
    } catch (error) {
      this.snackbar.open((error as Error).message);
    }
  }

  async confirm(name: string) {
    try {
      const newConfig = await firstValueFrom(this.configApi.createConfig(name, this.selectedStageId));
      if (newConfig) {
        await firstValueFrom(this.configApi.attachProject(newConfig.id, this.data.projectIdToAttach));
      }
      this.snackbar.open(`Configuration ${newConfig.name} créée.`, 'Ok', { duration: 3000 });
      this.matDialog.close(newConfig);
    } catch (error) {
      this.snackbar.open((error as Error).message);
    }
  }
}
