import { Component, Inject, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { firstValueFrom } from 'rxjs';
import { Config } from 'src/app/models/config.model';
import { Customer } from 'src/app/models/customer.model';
import { getNodeIcon } from 'src/app/models/global.model';
import { Project } from 'src/app/models/project.model';
import { ConfigCrudService } from 'src/app/services/crud/config-crud.service';
import { ProjectCrudService } from 'src/app/services/crud/project-crud.service';
import { UserService } from 'src/app/services/user.service';

export interface DialogDuplicateConfigData {
  configName: string;
  configId: number
  stageId?: number;
}

@Component({
  selector: 'app-dialog-duplicate-config-dialog',
  templateUrl: './dialog-duplicate-config-dialog.component.html',
  styleUrl: './dialog-duplicate-config-dialog.component.scss'
})
export class DialogDuplicateConfigDialogComponent implements OnInit {
  projectsCtrl = new FormControl<Project[]>([]);
  projects: Project[] = [];
  customers: Customer[] = [];

  icon: string = '';

  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogDuplicateConfigData,
  private matDialog: MatDialogRef<DialogDuplicateConfigDialogComponent>,
  private configApi: ConfigCrudService,
  private userService: UserService,
  private projectApi: ProjectCrudService,
  private snackbar: MatSnackBar) 
  {
    this.icon = getNodeIcon('config');
  }

  async ngOnInit() {
    try {
      this.projects = await firstValueFrom(this.projectApi.getProjects());
      this.customers = await this.userService.getUserCustomers();
    } catch (error) {
      this.snackbar.open((error as Error).message);
    }
  }

  async confirm(name: string) {
    try {
      if (this.projectsCtrl.value) {
        for await (const project of this.projectsCtrl.value) {
          const newConfig = await firstValueFrom(this.configApi.createConfig(name, this.data.stageId));
          await firstValueFrom(this.configApi.attachProject(newConfig.id, project.id));
        }
        this.snackbar.open(`Configuration ${name} dupliquée.`, 'Ok', { duration: 3000 });
      }
      this.matDialog.close(true);
    } catch (error) {
      this.snackbar.open((error as Error).message);
    }
  }
}
