<h2 mat-dialog-title> 
	<!-- <mat-icon>{{ icon }}</mat-icon> -->
	<span>Création de client</span>
</h2>
<mat-dialog-content class="mat-typography">
	<!-- Name -->
  <mat-form-field subscriptSizing="dynamic" floatLabel="always">
		<mat-label>Nouveau client</mat-label>
		<input matInput #input>
	</mat-form-field>

	<mat-form-field subscriptSizing="dynamic" floatLabel="always">
		<mat-label>Domaine</mat-label>
		<input matInput #domain>
	</mat-form-field>
	
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Annuler</button>
  <button mat-raised-button (click)="confirm(input.value, domain.value)" color="primary"
  [disabled]="!input.value">
  <mat-icon>edit</mat-icon>
  Confirmer</button>
</mat-dialog-actions>