import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

/**
 * Manage state of sidenav/mat-drawer.
 */
export class DrawerService {
  private drawerToggleSubject = new BehaviorSubject<boolean>(true);

  public toggleDrawer(toggle: boolean) {
    this.drawerToggleSubject.next(toggle);
  }

  getDrawerState(): Observable<boolean> {
    return this.drawerToggleSubject.asObservable();
  }
  
  constructor() {}
}
