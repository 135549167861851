import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { Stage } from 'src/app/models/stage.model';
import { environment } from 'src/environment';

@Injectable({
  providedIn: 'root'
})
export class StageCrudService {
  private baseUrl = `${environment.apiUrl}/stage`;

  constructor(private http: HttpClient) { }

  // Get
  getStages(): Observable<Stage[]> {
    return this.http.get<Stage[]>(this.baseUrl);
  }
  getStageById(stageId: number): Observable<Stage> {
    return this.http.get<Stage>(`${this.baseUrl}/${stageId}`);
  }
  // Post
  createStage(name: string, color?: string): Observable<Stage> {
    return this.http.post<Stage>(`${this.baseUrl}`, { name, color });
  }
  // Put
  editStage(stageId: number, stage: Partial<Stage>): Observable<Stage> {
    return this.http.put<Stage>(`${this.baseUrl}/${stageId}`, { id: stage.id, name: stage.name, color: stage.color });
  }
  // Delete
  deleteStage(stageId: number): Observable<any> {
    return this.http.delete(`${this.baseUrl}/${stageId}`, { responseType: 'text'});
  }
}
