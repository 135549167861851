import { Injectable } from '@angular/core';
import { UserService } from './user.service';
import { KeycloakService } from 'keycloak-angular';
import { KeycloakProfile } from 'keycloak-js';
import { MatSnackBar } from '@angular/material/snack-bar';
import { lastValueFrom, of } from 'rxjs';
import { UserCrudService } from './crud/user-crud.service';
import { User } from '../models/user.model';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public isLoggedIn = false;
  public userProfile: KeycloakProfile | null = null;
  public bearerToken: string = '';

  constructor(
    private readonly keycloak: KeycloakService,
    private userService: UserService,
    private snackbar: MatSnackBar,
    private userApi: UserCrudService) {}

  async login() {
    this.isLoggedIn = this.keycloak.isLoggedIn();

    if (this.isLoggedIn) {
      this.userProfile = await this.keycloak.loadUserProfile();

      if (this.userProfile.email) {
        this.bearerToken = await this.keycloak.getToken();
        // Define new user
        await this.userService.refreshUser(this.userProfile.email);
        this.snackbar.open($localize `Welcome !`, $localize `Thanks`, { duration: 4000 });
      }
    } else {
      await this.keycloak.login({idpHint: 'default'});
    }
  }

  // public login() {
  //   this.keycloak.login();
  // }

  public logout() {
    this.keycloak.logout();
  }
}
